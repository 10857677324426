<template>
  <div class="mt-3.5 w-full px-3">
    <div class="bg-[#F8F8F8] p-4">
      <h2
        class="overflow-hidden font-bold leading-7 md:max-w-[650px] md:text-xl"
      >
        <span class="line-clamp-2 text-primary">
          {{ car.name }}
        </span>
      </h2>

      <p class="text-sm text-onAccent1">
        {{ description }}
      </p>
    </div>
  </div>

  <div class="flex w-full flex-wrap justify-between gap-2 px-3 pb-2 pt-1">
    <div class="-mb-4 flex items-center gap-2.5 pb-2">
      <ElementsCarStaticActions
        :sca-popup-slug="scaPopupSlug"
        :car="car"
        size="small"
      />
      <AdditionInfo :car-id="car.id" />
    </div>

    <div class="ml-auto mt-2 flex items-end gap-2.5 md:mt-0">
      <ElementsCarSupplierLogo
        :car="car"
        class="flex items-center justify-end"
        :class="{
          'w-24 sm:w-32 md:w-48': car.dataAddition?.length,
          'w-32 sm:w-48': !car.dataAddition?.length
        }"
      />
      <ElementsCarDynamicActions :car="car" size="big" />
    </div>
  </div>
  <div class="relative grid grid-cols-2 overflow-hidden">
    <div class="absolute left-2 top-2 z-10">
      <ElementsCarHighestBidderTooltip :car="car" />
    </div>

    <Image class="object-center" :car-id="car.id" />
    <div class="relative">
      <div class="absolute inset-0 -z-10 bg-[#B8CE21] opacity-20"></div>

      <TimeInfo
        :car="car"
        :is-countdown-over="isCountdownOver"
        :time-remaining="timeRemaining"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BarometerCar } from '@autobid/ui/types/Car'
import { useCarDescription } from '@autobid/ui/composables/car/useCarDescription'
import AdditionInfo from '../../elements/car/AdditionInfo.vue'
import Image from './Image.vue'
import TimeInfo from './TimeInfo.vue'

type Props = {
  car: BarometerCar
  timeRemaining: string
  isCountdownOver: boolean
  title: string
  scaPopupSlug: string
}

const props = defineProps<Props>()

const { getDescription } = useCarDescription()
const description = getDescription(props.car)
</script>
